import ForumPage from "../pages/forum/ForumPage";
import ThreadPage from "../pages/forum/ThreadPage";

export default function ForumGroup () {
  return (
    <>
      <ForumPage />
      <ThreadPage />
    </>
  )
}